import { useTranslations } from 'next-intl'

type Props = { data: any }

export function CardComponent({ data }: Props) {
  // __STATE's
  const t = useTranslations()

  // __RENDER
  return (
    <div className='swiper-card'>
      <picture>
        <source srcSet={data.desktop} media='(min-width: 640px)' />
        <img
          className='size-full object-cover object-center'
          src={data.mobile}
          loading='lazy'
          alt={t('Index.SEC4_TITLE_PICTURE')}
        />
      </picture>

      <div className='absolute inset-0 z-10 grid grid-rows-[1fr_auto]'>
        <div className='rows grid'>
          <div className='place-self-center'>
            <h2 className='text-center text-2xl text-white'>{t('Index.SEC4_TITLE_PICTURE')}</h2>

            <a
              className='btn mx-auto mt-4 bg-theme-200 px-4 py-2 text-white'
              target='_blank'
              rel='noopener'
              href={t('Index.SEC4_URL')}
              aria-label={data.button}
              dangerouslySetInnerHTML={{ __html: t('Index.SEC4_BUTTON') }}
            />
          </div>
        </div>

        <div className='rows bg-theme-200/75 backdrop-blur-xl'>
          <div className='mx-auto max-w-screen-md p-4 text-center'>
            <h2 className='text-2xl font-bold text-theme'>{t('Index.SEC4_TITLE')}</h2>
            <p className='mt-1 text-sm text-theme-light'>{t('Index.SEC4_SUBTITLE')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
