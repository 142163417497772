'use client'

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  useDisclosure
} from '@nextui-org/react'
import { CircleXIcon } from 'lucide-react'
import { useLocale, useTranslations } from 'next-intl'
import { useState } from 'react'

import { BookingFormComponent } from '@/components/forms/booking'
import { fetchCommon } from '@/libs/actions/common.action'
import { stripHtml } from '@/libs/utils'

export function ButtonBooking() {
  // __STATE's
  const t = useTranslations()
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const [isMaintenance, setMaintenanceStatus] = useState(false)
  const [maintenanceMessage, setMaintenanceMessage] = useState(
    "We're sorry, our website is temporarily close for site enhancements. We'll be back soon!"
  )
  const locale = useLocale()

  const checkMaintenanceStatus = async () => {
    // Fetch the maintenance status from your API or service
    const response = await fetchCommon(locale)
    setMaintenanceStatus(response.MAINTENANCE.status)
    setMaintenanceMessage(response.MAINTENANCE.message)
    onOpen()
  }
  // __RENDER
  return (
    <>
      <button
        className='btn btn-primary mx-auto h-12 px-8'
        type='button'
        aria-label='Button Booknow'
        onClick={checkMaintenanceStatus}>
        <span
          className='text z-10 text-2xl font-light uppercase tracking-widest'
          dangerouslySetInnerHTML={{ __html: stripHtml(t.raw('Menu.BOOKNOW_BUTTON')) }}
        />
      </button>

      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size={isMaintenance ? 'sm' : 'md'}
        radius='sm'
        placement={isMaintenance ? 'center' : 'auto'}
        hideCloseButton={isMaintenance}>
        <ModalContent>
          {(onClose) => {
            return isMaintenance ? (
              <ModalBody className='py-6'>
                <div className='grid gap-2 text-center'>
                  <CircleXIcon className='mx-auto size-20 stroke-1 text-red-500' />
                  <div className='text-xl font-semibold text-zinc-800'>
                    System offline for maintenance
                  </div>
                  <p className='text-sm font-normal text-zinc-600'>{maintenanceMessage}</p>
                </div>

                <Button
                  className='mx-auto w-32 bg-red-600 text-lg text-white'
                  size='sm'
                  radius='sm'
                  onPress={onClose}>
                  OK
                </Button>
              </ModalBody>
            ) : (
              <>
                <ModalHeader className='px-8 pt-8'>Online Booking</ModalHeader>

                <ModalBody className='gap-6 px-8 pb-8 pt-0 sm:grid'>
                  <BookingFormComponent key='.form-online-booking' />
                </ModalBody>
              </>
            )
          }}
        </ModalContent>
      </Modal>
    </>
  )
}
