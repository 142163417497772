import { Link } from '@nextui-org/react'
import { Map } from 'lucide-react'
import { useLocale, useTranslations } from 'next-intl'

type Props = {
  branch: Branch
}

export function CardComponent({ branch }: Props) {
  // __STATE's
  const locale = useLocale()
  const t = useTranslations()

  const publicPath = `${branch.url}`

  // __RENDER
  return (
    <div className='swiper-card relative'>
      <div className='relative aspect-square overflow-hidden'>
        <a className='size-full' href={publicPath} aria-label={branch.name}>
          <img
            className='size-full bg-gray-200 object-cover object-center'
            loading='lazy'
            src={branch.image}
            alt={branch.name}
          />
        </a>

        <div className='absolute inset-x-0 bottom-0 bg-theme/80 p-4 backdrop-blur-xl'>
          <div className='flex items-center justify-between gap-2 text-theme-light'>
            <div className='columns'>
              <p className='text-xs font-light uppercase'>{t('Index.OPEN_HOUR_TEXT')}</p>
            </div>

            <div className='columns'>
              <p className='text-sm font-semibold'>
                {`${branch.open_timename} ~ `}
                {branch.close_timename === '00:00'
                  ? t('Index.MIDNIGHT_TEXT')
                  : branch.close_timename}
              </p>
            </div>

            <div className='columns self-center'>
              <Link href={branch.map_link} isExternal aria-label={branch.area}>
                <Map className='text-white' />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className='pt-2'>
        <h2 className='line-clamp-1 font-bold leading-9 text-theme'>
          <a href={publicPath} aria-label={branch.name}>
            <span className='text'>{branch.name}</span>
          </a>
        </h2>

        <p className='line-clamp-2 text-sm font-light text-black'>{branch.medium_desc}</p>

        <a
          className='my-4 text-sm text-gray-600 underline'
          href={publicPath}
          target='_parent'
          rel='external'
          aria-label={branch.name}>
          <span className='text'>{t('Index.SEC2_BUTTON')}</span>
        </a>

        {/* <ul className='hidden'>
          <li className='flex items-center gap-2'>
            <span className='icon bi bi-check-circle-fill text-theme-orange'></span>
            <span className='line-clamp-1 text-xs text-gray-800'>{branch.pickupArea}</span>
          </li>

          <li className='flex items-center gap-2'>
            <span className='icon bi bi-check-circle-fill text-theme-orange'></span>
            <span className='line-clamp-1 text-xs text-gray-800'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </span>
          </li>

          <li className='flex items-center gap-2'>
            <span className='icon bi bi-check-circle-fill text-theme-orange'></span>
            <span className='line-clamp-1 text-xs text-gray-800'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </span>
          </li>
        </ul> */}
      </div>
    </div>
  )
}
