'use client'

import { Button } from '@nextui-org/button'
import { union } from 'ramda'
import { useCallback, useEffect, useMemo, useState } from 'react'
import type { Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { findClosestLocation, geoBeanchLocation } from '@/libs/haversine'
import { cls } from '@/libs/utils'

import { CardComponent } from './location-card'

type Props = {
  branches: IBranch[]
}

export function LocationSliderComponent({ branches }: Props) {
  // __STATE's
  const [swiper, setSwiper] = useState<SwiperType | null>(null)
  const [currentCity, setCurrentCity] = useState<string>('BKK')

  const state = useMemo(
    () =>
      branches
        .map((r) => r.branches)
        .reduce<Branch[]>(union, [])
        .filter((r) => r.city_code === currentCity),

    [swiper, currentCity]
  )

  // __FUNCTION's
  const handleClick = useCallback(
    (cityCode: string) => {
      if (swiper) {
        setCurrentCity(cityCode)
        setTimeout(() => {
          swiper.slideReset()
          swiper.slideToLoop(0)
        }, 10)
      }
    },
    [swiper]
  )

  // __EFFECT's
  useEffect(() => {
    geoBeanchLocation(branches.map((r) => r.branches).reduce<Branch[]>(union, []))
      .then((branch) => {
        if (branch) handleClick(branch.city_code)
      })
      .catch((error) => {})
  }, [branches, handleClick])

  // __RENDER
  return (
    <>
      <div className='mx-auto grid w-full max-w-screen-sm grid-flow-col gap-1 px-4 sm:flex sm:justify-center md:gap-4'>
        {branches.map((record, index) => (
          <Button
            className={cls(
              'h-10 w-full hover:bg-secondary',
              record.code === currentCity ? '' : 'opacity-75'
            )}
            color='primary'
            radius='none'
            aria-label='City'
            onPress={() => handleClick(record.code)}
            key={index}>
            <span className='text-sm font-semibold uppercase'>{record.name}</span>
          </Button>
        ))}
      </div>
      <div className='relative mx-auto w-screen px-4 md:max-w-screen-xl md:px-8'>
        <Swiper
          slidesPerView={1}
          spaceBetween={16}
          speed={1200}
          autoplay={false}
          breakpoints={{
            640: {
              slidesPerView: Math.min(state.length, 3),
              spaceBetween: 32
            }
          }}
          centeredSlides={true}
          centerInsufficientSlides={true}
          centeredSlidesBounds={true}
          watchSlidesProgress={true}
          watchOverflow={true}
          onSwiper={setSwiper}>
          {state.map((record, index) => (
            <SwiperSlide key={index}>
              <CardComponent branch={record} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  )
}
