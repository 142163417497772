'use client'

import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { CardComponent } from './school-card'

type Props = {
  data: any[]
}

export function SliderComponent({ data }: Props) {
  // __STATE's

  // __RENDER
  return (
    <div className='mx-auto w-screen px-8 py-8 max-md:px-0 md:max-w-screen-xl'>
      <Swiper
        slidesPerView={1}
        spaceBetween={16}
        speed={1200}
        autoplay={false}
        breakpoints={{
          640: {
            slidesPerView: 3,
            spaceBetween: 32
          }
        }}
        pagination={{
          clickable: true
        }}
        centeredSlides={true}
        centerInsufficientSlides={true}
        centeredSlidesBounds={true}
        watchSlidesProgress={true}
        watchOverflow={true}
        navigation={true}
        modules={[Pagination, Navigation]}>
        {data.map((record, index) => (
          <SwiperSlide key={index}>
            <CardComponent data={record} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
